<template>
  <v-container fluid>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline">タリー割当設定</v-list-item-title>
        <v-list-item-subtitle>ドラッグアンドドロップで変更</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-switch
      v-model="disableDraggable"
      label="ロック"
    ></v-switch>
    <v-row
      v-for="(switcher, switcherIndex) in Switchers"
      :key="switcher.deviceID"
    >
      <v-col
        v-for="(port, portIndex) in switcher.tallyIN.items"
        :key="port.portID"
        cols="12"
        sm="6"
        md="6"
        lg="3"
        xl="3"
      >
        <v-card v-bind:class="tallyStates[switcherIndex][portIndex].tallyColor">
          <v-container>
            <v-list-item-content>
              <v-list-item-subtitle>{{
                switcher.deviceName
              }}</v-list-item-subtitle>
              <v-list-item-title>{{ port.portName }}</v-list-item-title>
            </v-list-item-content>
            <v-sheet rounded color="grey darken-3" min-height="50">
              <draggable
                :list="assignedCamerasSwitchers[switcherIndex][portIndex]"
                :options="{ group: 'ITEMS', animation: 200, disabled: disableDraggable }"
                @change="change($event, switcherIndex, portIndex)"
              >
                <v-list-item
                  class="px-1"
                  v-for="cam in assignedCamerasSwitchers[switcherIndex][
                    portIndex
                  ]"
                  :key="cam.deviceID"
                >
                  <v-btn block depressed><v-icon class="mr-1">mdi-video-wireless-outline</v-icon>  {{ cam.deviceName }}</v-btn>
                </v-list-item>
              </draggable>
            </v-sheet>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth } from "aws-amplify";
import { onUpdateTallyIN } from "../graphql/subscriptions";
import { updateTallyOUT } from "../graphql/mutations";
import { listDevices } from "../graphql/queries";
import draggable from "vuedraggable";

export default {
  components: {
    draggable: draggable,
  },
  data: () => ({
    devices: [],
    Cameras: [],
    Switchers: [],
    tallyStates: [],
    selectSwitcherID: {},
    selectSwitcherPortID: {},
    selectSwitcherPorts: [],
    assignedCamerasSwitchers: [],
    assignedCamerasSwitcherPorts: [],
    assignedCameras: [],
    newSourceDeviceID: "",
    newSourcePortID: "",
    pwm: 100,
    tallystate: "OFF",
    tally: false,
    disableDraggable: true,
  }),

  methods: {
    change: function (originalEvent, switcherIndex, portIndex) {
      console.log(originalEvent, switcherIndex, portIndex);
      if (originalEvent.added) {
        this.changeDeviceID = originalEvent.added.element.deviceID;
        this.newSourceDeviceID = this.Switchers[switcherIndex].deviceID;
        this.newSourcePortID = portIndex + 1;
        this.mutation(
          this.changeDeviceID,
          this.newSourceDeviceID,
          this.newSourcePortID
        );
        console.log(this.assignedCamerasSwitchers);
      }
    },
    mutation(deviceID, sourceDeviceID, sourcePortID) {
      console.log(deviceID);
      const messageUpdateTallyOUT = {
        deviceID: deviceID,
        portID: 1,
        sourceDeviceID: sourceDeviceID,
        sourcePortID: sourcePortID,
      };

      console.log("message", messageUpdateTallyOUT);

      API.graphql(
        graphqlOperation(updateTallyOUT, { input: messageUpdateTallyOUT })
      ).catch((error) => console.error("updateDevice", error));
      //this.onUpdateTallyINdeviceIDandPortIdSubscription.unsubscribe();
      //this.subscribe();
    },
    async fetch() {
  
      // GraphQL APIを叩く
      const items = await API.graphql(
        graphqlOperation(listDevices)
      ).catch((err) => console.error("listDevices", err));
      this.devices = items.data.listDevices.items;
      console.log("this.devices", this.devices);

      //switcherとcameraの振り分け
      for (var deviceIndex in this.devices) {
        console.log(deviceIndex);
        if (this.devices[deviceIndex].deviceType === "switcher") {
          this.Switchers.push(this.devices[deviceIndex]);
          console.log("this.Switchers", this.Switchers);
        }

        if (this.devices[deviceIndex].deviceType === "camera") {
          this.Cameras.push(this.devices[deviceIndex]);
          console.log("this.Cameras", this.Cameras);
        }
      }

      // 各配列をdeviceNameでソートする
      this.ObjArraySort(this.Switchers, "deviceName");
      this.ObjArraySort(this.Cameras, "deviceName");

      console.log("Switchers", this.Switchers);
      console.log("Cameras", this.Cameras);

      // switcherの入力に割り当てられたcamaeraをサーチして、switcher&入力ポートごとの二次元配列を作成とタリーステータスの二次元配列を作成
      for (var switcherIndex in this.Switchers) {
        // タリー情報の配列にswitcherごとに空配列をpush
        this.tallyStates.push([]);
        // スイッチャーとカメラの割当管理配列にswitcherごとに空配列をpush
        this.assignedCamerasSwitchers.push([]);
        this.assignedCamerasSwitcherPorts = [];
        for (var tallyINIndex in this.Switchers[switcherIndex].tallyIN.items) {
          // タリー情報の配列用ステータス情報の準備
          var tallyinput = this.Switchers[switcherIndex].tallyIN.items[
            tallyINIndex
          ].input;
          var color = "";
          if (tallyinput === "on") {
            color = "red";
          } else {
            color = "";
          }
          // タリー情報の配列用ステータス情報のオブジェクトの作成とpush
          var tallyINObj = { input: tallyinput, tallyColor: color };
          this.tallyStates[switcherIndex].push(tallyINObj);

          // スイッチャーとカメラの割当管理配列にtallyINごとに空配列をpush(二次元配列になる)
          this.assignedCamerasSwitchers[switcherIndex].push([]);

          // 割当カメラのサーチ
          for (var cameraIndex in this.Cameras) {
            if (
              this.Switchers[switcherIndex].tallyIN.items[tallyINIndex]
                .deviceID ===
                this.Cameras[cameraIndex].tallyOUT.items[0].sourceDeviceID &&
              this.Switchers[switcherIndex].tallyIN.items[tallyINIndex]
                .portID ===
                this.Cameras[cameraIndex].tallyOUT.items[0].sourcePortID
            ) {
              // 上記の条件に合致し割当られていたら、下記の配列にpush
              this.assignedCamerasSwitchers[switcherIndex][tallyINIndex].push(
                this.Cameras[cameraIndex]
              );
            }
          }
        }
      }
      console.log(
        "this.assignedCamerasSwitchers",
        this.assignedCamerasSwitchers
      );
      console.log("this.tallyStates", this.tallyStates);

      this.subscribe();
    },

    async subscribe() {
      this.onUpdateTallyIN = API.graphql(
        graphqlOperation(onUpdateTallyIN)
      ).subscribe({
        next: (data) => {
          console.log(data);
          for (var switcherIndex in this.Switchers) {
            if (
              data.value.data.onUpdateTallyIN.deviceID ===
              this.Switchers[switcherIndex].deviceID
            ) {
              this.Switchers[switcherIndex].tallyIN.items[
                data.value.data.onUpdateTallyIN.portID - 1
              ].input = data.value.data.onUpdateTallyIN.input;

              var tallyinput = data.value.data.onUpdateTallyIN.input;
              var color = "";
              if (tallyinput === "on") {
                color = "red";
              } else {
                color = "";
              }
              // タリー情報配列の更新
              this.tallyStates[switcherIndex][
                data.value.data.onUpdateTallyIN.portID - 1
              ].input = tallyinput;
              this.tallyStates[switcherIndex][
                data.value.data.onUpdateTallyIN.portID - 1
              ].tallyColor = color;
              console.log(this.tallyStates);
            }
          }
        },
      });
    },

    ObjArraySort(ary, key, order) {
      var reverse = 1;
      if (order && order.toLowerCase() == "desc") reverse = -1;
      ary.sort(function (a, b) {
        if (a[key] < b[key]) return -1 * reverse;
        else if (a[key] == b[key]) return 0;
        else return 1 * reverse;
      });
    },
  },
  async created() {
    this.userName = (await Auth.currentAuthenticatedUser()).username;
    //this.fetch();
  },
  mounted() {
    this.fetch();
  },
  beforeDestroy() {
    this.onUpdateTallyIN.unsubscribe();
  },
};
</script>
