/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onCreateDevice(filter: $filter) {
      deviceID
      deviceName
      description
      deviceType
      deviceGroup
      serialNo
      swVersion
      hwVersion
      ipChgStatus
      ethIpAddress
      ethSubnet
      ethGateway
      ethDns
      swVerUpTrigger
      tallyIN {
        items {
          deviceID
          portID
          input
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tallyOUT {
        items {
          deviceID
          portID
          portPWM
          sourceDeviceID
          sourcePortID
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location {
        items {
          deviceID
          lat
          lng
          alt
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onUpdateDevice(filter: $filter) {
      deviceID
      deviceName
      description
      deviceType
      deviceGroup
      serialNo
      swVersion
      hwVersion
      ipChgStatus
      ethIpAddress
      ethSubnet
      ethGateway
      ethDns
      swVerUpTrigger
      tallyIN {
        items {
          deviceID
          portID
          input
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tallyOUT {
        items {
          deviceID
          portID
          portPWM
          sourceDeviceID
          sourcePortID
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location {
        items {
          deviceID
          lat
          lng
          alt
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice($filter: ModelSubscriptionDeviceFilterInput) {
    onDeleteDevice(filter: $filter) {
      deviceID
      deviceName
      description
      deviceType
      deviceGroup
      serialNo
      swVersion
      hwVersion
      ipChgStatus
      ethIpAddress
      ethSubnet
      ethGateway
      ethDns
      swVerUpTrigger
      tallyIN {
        items {
          deviceID
          portID
          input
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      tallyOUT {
        items {
          deviceID
          portID
          portPWM
          sourceDeviceID
          sourcePortID
          portName
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      location {
        items {
          deviceID
          lat
          lng
          alt
          description
          device {
            deviceID
            deviceName
            description
            deviceType
            deviceGroup
            serialNo
            swVersion
            hwVersion
            ipChgStatus
            ethIpAddress
            ethSubnet
            ethGateway
            ethDns
            swVerUpTrigger
            createdAt
            updatedAt
            __typename
          }
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation($filter: ModelSubscriptionLocationFilterInput) {
    onCreateLocation(filter: $filter) {
      deviceID
      lat
      lng
      alt
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation($filter: ModelSubscriptionLocationFilterInput) {
    onUpdateLocation(filter: $filter) {
      deviceID
      lat
      lng
      alt
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation($filter: ModelSubscriptionLocationFilterInput) {
    onDeleteLocation(filter: $filter) {
      deviceID
      lat
      lng
      alt
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTallyIN = /* GraphQL */ `
  subscription OnCreateTallyIN($filter: ModelSubscriptionTallyINFilterInput) {
    onCreateTallyIN(filter: $filter) {
      deviceID
      portID
      input
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTallyIN = /* GraphQL */ `
  subscription OnUpdateTallyIN($filter: ModelSubscriptionTallyINFilterInput) {
    onUpdateTallyIN(filter: $filter) {
      deviceID
      portID
      input
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTallyIN = /* GraphQL */ `
  subscription OnDeleteTallyIN($filter: ModelSubscriptionTallyINFilterInput) {
    onDeleteTallyIN(filter: $filter) {
      deviceID
      portID
      input
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onCreateTallyOUT = /* GraphQL */ `
  subscription OnCreateTallyOUT($filter: ModelSubscriptionTallyOUTFilterInput) {
    onCreateTallyOUT(filter: $filter) {
      deviceID
      portID
      portPWM
      sourceDeviceID
      sourcePortID
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onUpdateTallyOUT = /* GraphQL */ `
  subscription OnUpdateTallyOUT($filter: ModelSubscriptionTallyOUTFilterInput) {
    onUpdateTallyOUT(filter: $filter) {
      deviceID
      portID
      portPWM
      sourceDeviceID
      sourcePortID
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const onDeleteTallyOUT = /* GraphQL */ `
  subscription OnDeleteTallyOUT($filter: ModelSubscriptionTallyOUTFilterInput) {
    onDeleteTallyOUT(filter: $filter) {
      deviceID
      portID
      portPWM
      sourceDeviceID
      sourcePortID
      portName
      description
      device {
        deviceID
        deviceName
        description
        deviceType
        deviceGroup
        serialNo
        swVersion
        hwVersion
        ipChgStatus
        ethIpAddress
        ethSubnet
        ethGateway
        ethDns
        swVerUpTrigger
        tallyIN {
          items {
            deviceID
            portID
            input
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        tallyOUT {
          items {
            deviceID
            portID
            portPWM
            sourceDeviceID
            sourcePortID
            portName
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        location {
          items {
            deviceID
            lat
            lng
            alt
            description
            createdAt
            updatedAt
            __typename
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
